<template>
  <div>
    <b-card title="Integrações Sistema">
      <validation-observer ref="form">
        <b-form>
          <b-row md="12">
            <b-col cols="3">
              <h5>Integrações de Folha</h5>
              <b-form-group label="Desconto em Folha" label-for="descontoEmFolha">
                <validation-provider #default="{ errors }" name="Desconto em Folha">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="descontoEmFolha"
                      type="text"
                      v-model="integracoes.descontoEmFolha"
                      placeholder="Link de integração"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <h5>Portifólio de investimentos</h5>
              <b-form-group label="Atualização de índice" label-for="portifolioInvestimento">
                <validation-provider #default="{ errors }" name="Portifólio de Investimentos">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="portifolioInvestimento"
                      type="text"
                      v-model="integracoes.portifolioInvestimento"
                      placeholder="Link de integração"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row md="12">
            <div class="w-100 mx-1 my-1 linhaDivisoria"></div>
            <b-col class="my-1" cols="12">
              <h5>Integrações de Boleto</h5>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Emissão de boleto" label-for="emissaoBoleto">
                <validation-provider #default="{ errors }" name="Emissão de boleto">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="emissaoBoleto"
                      type="text"
                      v-model="integracoes.emissaoBoleto"
                      placeholder="Link de integração"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row md="12">
            <div class="w-100 mx-1 my-1 linhaDivisoria"></div>
            <b-col class="my-1" cols="12">
              <h5>Integrações de Dashboard</h5>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Dashboard Cadastro" label-for="dashboardCadastro">
                <validation-provider #default="{ errors }" name="Dashboard Cadastro">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="dashboardCadastro"
                      type="text"
                      v-model="integracoes.dashboardCadastro"
                      placeholder="Link de integração"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Dashboard Proposta" label-for="dashboardProposta">
                <validation-provider #default="{ errors }" name="Dashboard Proposta">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="dashboardProposta"
                      type="text"
                      v-model="integracoes.dashboardProposta"
                      placeholder="Link de integração"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Dashboard Financeiro" label-for="dashboardFinanceiro">
                <validation-provider #default="{ errors }" name="Dashboard Financeiro">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="dashboardFinanceiro"
                      type="text"
                      v-model="integracoes.dashboardFinanceiro"
                      placeholder="Link de integração"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            style="width: 160px"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="authESalve"
          >
            {{ !isBusy ? 'Salvar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            style="width: 160px"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="limpar"
          >
            Limpar
          </b-button>
        </b-form>
      </validation-observer>

      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card>
  </div>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../../error/nao-autorizado-modal.vue'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      AutenticaSenha,
      NaoAutorizadoModal,
      required,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        mode: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        integracoes: {
          descontoEmFolha: null,
          emissaoBoleto: null,
          dashboardCadastro: null,
          dashboardProposta: null,
          dashboardFinanceiro: null,
          idTabela: null,
          portifolioInvestimento: null,
        },
      }
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      limpar() {
        this.integracoes.descontoEmFolha = null
        this.integracoes.emissaoBoleto = null
        this.integracoes.dashboardCadastro = null
        this.integracoes.dashboardProposta = null
        this.integracoes.dashboardFinanceiro = null
        this.integracoes.portifolioInvestimento = null
      },
      async carregarGrid() {
        const institutoSelecionado = this.userData.institutoSelecionado

        useJwt
          .get(`integracoes/${institutoSelecionado}`)
          .then((response) => {
            let itemsIntegracoes = response.data
            this.integracoes.descontoEmFolha = itemsIntegracoes.descontoEmFolha ?? null
            this.integracoes.emissaoBoleto = itemsIntegracoes.emissaoBoleto ?? null
            this.integracoes.dashboardCadastro = itemsIntegracoes.dashboardCadastro ?? null
            this.integracoes.dashboardProposta = itemsIntegracoes.dashboardProposta ?? null
            this.integracoes.dashboardFinanceiro = itemsIntegracoes.dashboardFinanceiro ?? null
            this.integracoes.idTabela = itemsIntegracoes.id
            this.integracoes.portifolioInvestimento = itemsIntegracoes.portifolioInvestimento ?? null
          })
          .catch((error) => {
            this.$message.error('Erro ao carregar os dados')
            console.error('error', error)
          })
      },
      salvar() {
        this.isBusy = true

        const id = this.userData.institutoSelecionado
        const dados = {
          id: this.integracoes.idTabela || null,
          descontoEmFolha: this.integracoes.descontoEmFolha || null,
          emissaoBoleto: this.integracoes.emissaoBoleto || null,
          dashboardCadastro: this.integracoes.dashboardCadastro || null,
          dashboardProposta: this.integracoes.dashboardProposta || null,
          dashboardFinanceiro: this.integracoes.dashboardFinanceiro || null,
          portifolioInvestimento: this.integracoes.portifolioInvestimento || null,
        }
        if (this.integracoes.idTabela) {
          useJwt
            .patch(`integracoes/${id}`, dados)
            .then(() => {
              this.$message.success('Dados atualizados com sucesso!')
            })
            .catch((error) => {
              console.error('error', error)
              this.$message.error('Erro ao atualizar os dados de integração!')
            })
            .finally(() => {
              this.isBusy = false
            })
        } else {
          useJwt
            .post(`integracoes/${id}`, dados)
            .then(() => {
              this.$message.success('Dados atualizados com sucesso!')
            })
            .catch((error) => {
              console.error('error', error)
              this.$message.error('Erro ao salvar os dados de integração!')
            })
            .finally(() => {
              this.isBusy = false
            })
        }
      },
      authESalve() {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
    },
  }
</script>
<style>
  .linhaDivisoria {
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }

  .dark-layout .linhaDivisoria {
    border-bottom: 1px solid #3b4253;
  }
</style>
