<template>
  <div>
    <b-tabs pills>
      <b-tab title="Efetivo" v-if="userData.Instituto.capag == 'a'">
        <b-card-text>
          <categoriaCriteriosElegibilidade :tipo="'Efetivo'" :userData="userData" />
        </b-card-text>
      </b-tab>
      <b-tab title="Estável" v-if="userData.Instituto.capag == 'a'">
        <b-card-text>
          <categoriaCriteriosElegibilidade :tipo="'Estável'" :userData="userData" />
        </b-card-text>
      </b-tab>
      <b-tab title="Pensionista">
        <b-card-text>
          <categoriaCriteriosElegibilidade :tipo="'Pensionista'" :userData="userData" />
        </b-card-text>
      </b-tab>
      <b-tab title="Aposentado">
        <b-card-text>
          <template>
            <categoriaCriteriosElegibilidade :tipo="'Aposentado'" :userData="userData" />
          </template>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import categoriaCriteriosElegibilidade from './CategoriaCriteriosElegibilidade.vue'

  export default {
    components: {
      categoriaCriteriosElegibilidade,
    },
    mounted() {},
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
  }
</script>
